<template>
  <div class="vg_wrapper">
    <el-card>
      <!--      <div class="vd_search_group">-->
      <!--        <el-form :inline="true" size="min" label-width="120px" :model="searchForm" ref="searchForm" @keyup.enter.native="getCustsNow()">-->
      <!--          <el-row>-->
      <!--            <el-col :md="8">-->
      <!--              <el-form-item label="素材类型:" prop="qtso_type">-->
      <!--                <el-select v-model="searchForm.qtso_type" size="small" clearable>-->
      <!--                  <el-option v-for="item in qtsoTypeList" :key="item.value" :value="item.value" :label="item.label"> </el-option>-->
      <!--                </el-select>-->
      <!--              </el-form-item>-->
      <!--            </el-col>-->
      <!--            <el-col :md="8">-->
      <!--              <el-form-item label="材料名称:" prop="qtso_name">-->
      <!--                <el-input size="small" v-model.trim="searchForm.qtso_name" clearable placeholder="请填写材料名称"></el-input>-->
      <!--              </el-form-item>-->
      <!--            </el-col>-->
      <!--            <el-col :md="8">-->
      <!--              <el-form-item label="单据状态:" prop="status">-->
      <!--                <el-select filterable v-model="searchForm.status" placeholder="请选择录入人" size="small" clearable>-->
      <!--                  <el-option v-for="item in statusList" :key="item.id" :label="item.label" :value="item.id"> </el-option>-->
      <!--                </el-select>-->
      <!--              </el-form-item>-->
      <!--            </el-col>-->
      <!--          </el-row>-->
      <!--          <el-row>-->
      <!--            <el-col :md="8">-->
      <!--              <el-form-item label="录入人:" prop="stff_id">-->
      <!--                <el-select filterable v-model="searchForm.stff_id" placeholder="请选择录入人" size="small" clearable>-->
      <!--                  <el-option v-for="item in stffUserList" :key="item.stff_id" :label="item.stff_name" :value="item.stff_id"> </el-option>-->
      <!--                </el-select>-->
      <!--              </el-form-item>-->
      <!--            </el-col>-->

      <!--            <el-col :md="14">-->
      <!--              <el-form-item label="录入时间:" prop="timeValue">-->
      <!--                <div class="block">-->
      <!--                  <el-date-picker-->
      <!--                    size="small"-->
      <!--                    v-model="searchForm.timeValue"-->
      <!--                    type="datetimerange"-->
      <!--                    range-separator="至"-->
      <!--                    start-placeholder="开始日期"-->
      <!--                    end-placeholder="结束日期"-->
      <!--                  >-->
      <!--                  </el-date-picker>-->
      <!--                </div>-->
      <!--              </el-form-item>-->
      <!--              <el-form-item>-->
      <!--                <el-button size="small" type="primary" icon="el-icon-search" @click="getCustsNow()" class="vg_ml_16">查询 </el-button>-->
      <!--                <el-button type="info" size="small" class="vd_export" @click="buttonRefresh()"-->
      <!--                  ><i class="el-icon-refresh-right"></i> 刷新</el-button-->
      <!--                >-->
      <!--              </el-form-item>-->
      <!--            </el-col>-->
      <!--          </el-row>-->
      <!--        </el-form>-->
      <!--      </div>-->
      <div class="vd_button_group vg_mtb_16">
        <el-button type="primary" size="small" @click="addRow()" :disabled="!btn.add"><i class="el-icon-plus"></i> 新增 </el-button>
        <el-button type="danger" size="small" :disabled="!btn.delete" @click="doDelete()"><i class="el-icon-delete"></i> 删除</el-button>
        <el-button class="vd_export" size="small" type="info" @click="buttonRefresh()"><i class="el-icon-refresh-right"></i> 刷新</el-button>
      </div>
      <!--      <el-row>-->
      <!--        <el-col :md="24">-->
      <!--          <el-table-->
      <!--            class="vg_cursor"-->
      <!--            ref="multiTable"-->
      <!--            :data="tableData"-->
      <!--            @selection-change="handleSelectionChange"-->
      <!--            @select="selectRows"-->
      <!--            @select-all="selectRows"-->
      <!--            @row-dblclick="dbClickJp"-->
      <!--            border-->
      <!--            v-loading="loadFlag"-->
      <!--          >-->
      <!--            <el-table-column type="selection" width="48" align="center" />-->
      <!--            <el-table-column label="素材类型" prop="qtso_type" :formatter="formatterQtsoType" />-->
      <!--            <el-table-column label="素材名称" prop="qtso_name" />-->
      <!--            <el-table-column label="素材规格" prop="qtso_spec" show-overflow-tooltip>-->
      <!--              <template slot-scope="scope">-->
      <!--                <span v-if="scope.row.qtso_spec">{{ scope.row.qtso_spec }}</span>-->
      <!--                <span v-else class="vg_9f9a9a">暂无</span>-->
      <!--              </template>-->
      <!--            </el-table-column>-->
      <!--            <el-table-column label="单价" prop="qtso_price" align="right">-->
      <!--              <template slot-scope="scope">-->
      <!--                <div style="textalign: right">{{ helper.haveFour(scope.row.qtso_price) }}</div>-->
      <!--              </template>-->
      <!--            </el-table-column>-->
      <!--            <el-table-column label="是否是日价" prop="qtso_daypr" :formatter="formatterQtsoDayPr" />-->
      <!--            <el-table-column label="是否计算损耗" prop="qtso_loss" :formatter="formatterQtsoLoss" />-->
      <!--                  <el-table-column label="录入人" prop="stff_name" show-overflow-tooltip :formatter="helper.personCombina" />-->
      <!--            <el-table-column label="录入时间" prop="create_time" :formatter="formatDate" />-->
      <!--            <el-table-column label="单据状态" :show-overflow-tooltip="true" align="center">-->
      <!--              <template slot-scope="scope">-->
      <!--                <el-tag :type="helper.getStatusName(scope.row.status).type" size="mini">-->
      <!--                  {{ helper.getStatusName(scope.row.status).name }}</el-tag-->
      <!--                >-->
      <!--              </template>-->
      <!--            </el-table-column>-->
      <!--          </el-table>-->
      <!--        </el-col>-->
      <!--      </el-row>-->
      <!--      <el-row>-->
      <!--        <el-col :md="24">-->
      <!--          <pubPagination :totalPage="totalPage" @changePageSearch="changePageSearch" ref="pubPagination"> </pubPagination>-->
      <!--        </el-col>-->
      <!--      </el-row>-->
      <search-table
        ref="multiTable"
        :data="tableData"
        :tableRowKey="`stff_ext_id`"
        :columns="tableProperties"
        v-loading="loadFlag"
        :totalPage="totalPage"
        @selection-change="handleSelectionChange"
        @getTableData="getDequsNow"
        @row-dblclick="dbClickJp"
      >
        <template v-slot:status="scope">
          <div style="text-align: center">
            <el-tag :type="helper.getStatusName(scope.row.status).type" size="mini">
              {{ helper.getStatusName(scope.row.status).name }}
            </el-tag>
          </div>
        </template>
      </search-table>
    </el-card>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { QtsoAPI } from '@api/modules/qtso';
import { stffAPI } from '@/api/modules/staff';
import pubPagination from '@/components/common/pubPagination';
import SearchTable from '@/components/table/SearchTableV2.vue';
import { debounce } from 'lodash';
import helper from '@assets/js/helper';
import UrlEncode from '@assets/js/UrlEncode';

export default {
  name: 'CustList',
  components: {
    SearchTable,
    pubPagination
  },
  data() {
    return {
      tableProperties: [
        { prop: 'selection', type: 'selection', labelWidth: '48px' },
        {
          prop: 'qtso_type',
          label: '素材类型',
          itemType: 'select',
          options: [
            { value: '1', label: '原材料' },
            { value: '2', label: '香精' },
            { value: '3', label: '夜光粉' },
            { value: '4', label: '颗粒' },
            { value: '5', label: '矿粉' },
            { value: '6', label: '色粉' },
            { value: '7', label: '成品损耗' },
            { value: '8', label: '辅助料材' },
            { value: '9', label: '水电费' },
            { value: '10', label: '运费' },
            { value: '11', label: '纸箱' },
            { value: '12', label: '塑料袋' },
            { value: '13', label: '人工工资' }
          ],
          widthAuto: true,
          formatter: val => this.formatterQtsoType(val)
        },
        { prop: 'qtso_name', label: '素材名称', itemType: 'input', widthAuto: true },
        { prop: 'qtso_spec', label: '素材规格', itemType: 'input', input: false, widthAuto: true },
        { prop: 'qtso_price', label: '单价', itemType: 'input', input: false, widthAuto: true, formatter: val => helper.haveFour(val) },
        {
          prop: 'qtso_daypr',
          label: '是否是日价',
          itemType: 'input',
          input: false,
          widthAuto: true,
          formatter: val => this.formatterQtsoDayPr(val)
        },
        {
          prop: 'qtso_loss',
          label: '是否计算损耗',
          itemType: 'input',
          input: false,
          widthAuto: true,
          formatter: val => this.formatterQtsoLoss(val)
        },
        { prop: 'stff_name', label: '录入人', itemType: 'input', widthAuto: true },
        { prop: 'create_time', label: '录入时间', itemType: 'date', widthAuto: true, formatter: val => this.helper.toStringDate(val) },
        {
          prop: 'status',
          label: '单据状态',
          itemType: 'select',
          fixed: 'right',
          options: [
            { value: 0, label: '草拟' },
            { value: 1, label: '在批' },
            { value: 2, label: '生效' }
          ]
        }
      ],
      tableData: [],
      searchForm: {
        qtso_type: null, //素材类型
        qtso_name: null, //材料名称
        stff_id: null,
        status: null,
        timeValue1: null
      },
      totalPage: 0,
      btn: {},
      loadFlag: true,
      multiSelection: [],
      currentPage: 1,
      stffUserList: [],
      qtsoTypeList: [
        { value: '1', label: '原材料' },
        { value: '2', label: '香精' },
        { value: '3', label: '夜光粉' },
        { value: '4', label: '颗粒' },
        { value: '5', label: '矿粉' },
        { value: '6', label: '色粉' },
        { value: '7', label: '成品损耗' },
        { value: '8', label: '辅助料材' },
        { value: '9', label: '水电费' },
        { value: '10', label: '运费' },
        { value: '11', label: '纸箱' },
        { value: '12', label: '塑料袋' },
        { value: '13', label: '人工工资' }
      ],
      statusList: [
        {
          id: 0,
          label: '草拟'
        },
        {
          id: 1,
          label: '在批'
        },
        {
          id: 2,
          label: '生效'
        }
      ]
    };
  },
  created() {
    this.initData();
  },
  watch: {
    $route(to, from) {
      if (from.path === '/qtso_add' || from.path === '/qtso_edit') {
        this.initData();
      }
    }
  },
  methods: {
    initData() {
      this.getDequsNow();
      this.getStffUser();
    },
    // 查询方法
    getDequsNow: debounce(function () {
      this.loadFlag = true;
      this.getDataList();
    }, 1000),
    getDataList() {
      // const timeNewVal = this.helper.getTime(this.searchForm.timeValue);
      let searchForm = this.$refs.multiTable.searchForm;
      get(QtsoAPI.getQtsos, searchForm)
        .then(res => {
          if (res.data.code === 0) {
            this.loading = false;
            this.tableData = res.data.data.list;
            this.totalPage = res.data.data.total;
            this.btn = res.data.data.btn;
            setTimeout(() => {
              this.loadFlag = false;
            }, 500);
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(() => {});
    },

    getStffUser() {
      get(stffAPI.getAllStffsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.stffUserList = res.data.data;
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    // 刷新
    buttonRefresh() {
      this.$refs.multiTable.resetFields();
      // this.$refs.searchForm.resetFields();
      this.loadFlag = true;
      // this.currentPage = 1;
      this.getDataList();
      // this.$refs.pubPagination.currentPage = 1;
    },
    // 查询方法
    getCustsNow() {
      this.loadFlag = true;
      this.$refs.pubPagination.currentPage = 1;
      this.currentPage = 1;
      this.getDataList();
    },
    // 多选
    handleSelectionChange(val) {
      this.multiSelection = val;
    },
    // 判断是否可以删除
    selectRows(selection) {
      this.btn.delete = this.helper.isDelete(selection);
    },
    // 双击row跳转到详情编辑
    dbClickJp(row) {
      this.jump('/qtso_edit', {
        key: UrlEncode.encode(
          JSON.stringify({
            perm_id: this.$route.query.perm_id,
            form_id: row.qtso_id
          })
        )
      });
    },
    // //新增
    addRow() {
      const permId = this.$route.query.perm_id;
      const personal = this.$cookies.get('userInfo').personal;
      this.jump(`/qtso_add`, {
        key: UrlEncode.encode(JSON.stringify({ perm_id: permId, personal: personal }))
      });
      // const permId = this.$route.query.perm_id
      // this.jump(`/cust_add?perm_id=${permId}`)
    },
    // 分页查询
    changePageSearch(val) {
      this.loadFlag = true;
      this.currentPage = val;
      this.getDataList();
    },
    // 时间转换
    formatDate(row) {
      return this.helper.toStringDate(row.create_time);
    },
    // 未选中提示
    doDelete() {
      if (this.multiSelection.length === 0) {
        this.$message({ type: 'warning', message: '请至少选择一条数据！' });
      } else {
        let temp = false;
        for (let i = 0; i < this.multiSelection.length; i++) {
          if (this.multiSelection[i].status === 2) {
            temp = true;
            break;
          }
        }
        if (temp) {
          this.$message({ type: 'error', message: '此单据已生效或者正在审批中,无法删除！' });
          this.$refs.multiTable.clearSelection();
        } else {
          this.mBox();
        }
      }
    },
    //客户信息list删除提示
    mBox() {
      this.$confirm('确定删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          const ids = [];
          this.multiSelection.map(item => {
            ids.push(item.qtso_id);
          });
          post(QtsoAPI.deleteQtsoByIds, { qtso_id_list: ids })
            .then(res => {
              let mg = res.data.msg;
              if (res.data.code === 0) {
                let tp = 'success';
                this.$message({ message: mg, type: tp });
                if (ids.length === this.tableData.length) {
                  if (this.currentPage > 1) {
                    this.currentPage = this.currentPage - 1;
                    this.$refs.pubPagination.currentPage = this.currentPage;
                  }
                }
                this.initData();
              } else {
                let tp = 'error';
                this.$message({ message: mg, type: tp });
                this.initData();
              }
            })
            .catch(res => {
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({ message: mg, type: tp });
              this.initData();
            });
        })
        .catch(() => {
          let mg = '取消删除！';
          let tp = 'info';
          this.$message({ message: mg, type: tp });
        });
    },
    formatterQtsoType(qtso_type) {
      let str = '';
      this.qtsoTypeList.forEach(item => {
        if (qtso_type == item.value) {
          str = item.label;
        }
      });
      return str;
    },
    formatterQtsoDayPr(qtso_daypr) {
      if (qtso_daypr === 1) {
        return '是';
      }
      return '否';
    },
    formatterQtsoLoss(qtso_loss) {
      if (qtso_loss === 1) {
        return '是';
      }
      return '否';
    }
  }
};
</script>

<style scoped lang="scss">
.vd_elimg {
  width: 60px;
  height: 60px;
}
</style>
